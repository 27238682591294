<script>
import PropostaDetalhe from '@/components/shared/proposta/PropostaDetalhe.vue'

export default {
  extends: PropostaDetalhe,

  methods: {
    reprovar() {
      this.service
        .cancelar(this.proposta)
        .then(() => {
          this.exibeToast('success', 'Proposta cancelada com sucesso!')
          this.carregarProposta()
        })
        .catch((err) => {
          this.exibeToast('error', err.response.data.message)
        })
    },
  },
}
</script>
